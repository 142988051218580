<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../public/images/join/head.jpg" width="100%">
    <div>
      <div class="join_tile">
        招聘岗位
      </div>
      <p style="text-align:center">我们根据候选人的具体能力水平面谈协商，给出具有市场竞争力的薪资。</p>
    </div>
    <div>
      <el-tabs type="border-card">
        <el-tab-pane label="研发工程师">暂无招聘计划，敬请留意。</el-tab-pane>
        <el-tab-pane label="产品经理">暂无招聘计划，敬请留意。</el-tab-pane>
        <el-tab-pane label="项目经理">暂无招聘计划，敬请留意。</el-tab-pane>
        <el-tab-pane label="测试工程师">暂无招聘计划，敬请留意。</el-tab-pane>
      </el-tabs>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "Join",
  components: {
    elMenu,foot,navigation
  },

}
</script>

<style scoped>
.join_tile {
  margin-top: 72px;
  text-align: center;
  font-size: 24px;
}
/deep/.el-tabs--border-card{
  height:420px;
}
</style>